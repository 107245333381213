<template>
  <div>Settings</div>
</template>

<script>
export default {
}
</script>

<style scoped>

</style>
